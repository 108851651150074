<template>
  <div role="dialog"
       class="modal dialog fade" @click="$emit('close')">
    <div role="document" class="modal-dialog" @click.stop>
      <div class="dialog-content modal-content">
        <div class="modal-header" v-if="false"></div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="false"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'i-dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show: function (show) {
      const el = this.$el
      if (show) {
        el.style.display = 'block'
        setTimeout(() => el.classList.add('in'), 0)
        document.body.classList.add('modal-open')
      } else {
        el.classList.remove('in')
        document.body.classList.remove('modal-open')
        el.addEventListener('transitionend', this.handleTransitionEnd, false)
      }
    }
  },
  created () {},
  methods: {
    handleTransitionEnd () {
      this.$el.removeEventListener('transitionend', this.handleTransitionEnd, false)
      this.$el.style.display = 'none'
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  .modal {
    transition: all 0.3s ease;
  }
.fade {
  &.in {
    background-color: rgba(0,0,0,0.5);
  }
}
</style>
