<template>
  <div class="index-banner" role="banner">
    <cover-holder :height="coverHeight" />
    <div class="hero-swiper">
      <swiper class="swiper-wrapper" :options="option">
        <swiper-slide v-for="item in banners" :key="item.name" class="swiper-slide p-2">
          <div @click="checkDetail(item)">
            <template v-if="item.type !== 'event'">
              <img :src="item.image" alt="">
              <div class="index-banner-desc" v-if="item.showInfo">
                <div class="container">
                  <div>
                    <h1>{{item.title}}</h1>
                    <div class="subtitle hidden-xs">{{item.subtitle}}</div>
                    <button class="btn primary">
                      了解{{item.label}} <i class="fa fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <cover-holder v-else :src="item.image" :height="coverHeight"/>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import CoverHolder from '../../components/CoverHolder'
import { mapGetters } from 'vuex'
export default {
  name: 'HomeBanner',
  components: { CoverHolder },
  data: () => ({
    coverHeight: 41.7,
    showProm: false,
    eventLoaded: false,
    imageLoaded: false,
    imageConfig: {},
    promConfig: {},
    swiper: null,
    option: {
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      }
    },
    banners: [
      {
        type: 'product',
        name: 'diarybook',
        showInfo: true,
        label: '時光書',
        title: '印出你的“臉書”，“Instagram”',
        subtitle: '一鍵導入你的“臉書”，“IG”內容，自動排版',
        image: require('../../../static/images/home/hero-1.jpg')
      }
      // {
      //   type: 'product',
      //   name: 'photobook',
      //   showInfo: true,
      //   label: '相片書',
      //   title: '用相片記錄感動',
      //   subtitle: '一本專屬自己的相片書',
      //   image: require('../../../static/images/home/hero-2.jpg')
      // }
    ]
  }),
  computed: {
    ...mapGetters(['promotionLoaded', 'promotionConf', 'promotionImages'])
  },
  watch: {
    promotionLoaded: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.banners.unshift({
            type: 'event',
            showInfo: false,
            image: this.promotionImages.large
          })
        })
      }
    }
  },
  created () {
    if (this.promotionLoaded && this.promotionImages.large) {
      this.banners.unshift({
        type: 'event',
        showInfo: false,
        image: this.promotionImages.large
      })
    }
  },
  methods: {
    checkDetail (item) {
      if (item.type === 'event') {
        window.location.href = this.promotionConf.introLink
        return
      }
      this.$router.push({ path: '/products', query: { type: item.name } })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  @import '../../styles/variables';
  .index-banner {
    overflow: hidden;
    position: relative;
    .hero-swiper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      img {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
      }
    }
    &-desc {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .container {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .subtitle {
        margin-bottom: 1rem;
      }
      h1 {
        font-size: 32px;
        font-weight: 600;
        color: $el-color-primary;
        margin-bottom: 10px;
      }
      button i {
        margin-left: 4px;
      }
    }
  }
  @media (max-width: 768px) {
    .index-banner {
      margin-top: 0;
      margin: 1rem;
      border-radius: 8px;
      overflow: hidden;
      .container {
        padding: 0 1rem;
      }
      &-desc {
        h1 {
          font-size: 20px;
        }
      }
    }
  }
</style>
