/**
 *
 * @param target (window)
 * @param isTop (Boolean)
 */
// 返回当前页面相对于窗口显示区左上角的X,Y的位置
export function getScroll (target, top) {
  const prop = top ? 'pageYOffset' : 'pageXOffset'
  const method = top ? 'scrollTop' : 'scrollLeft'
  const isWindow = target === window

  let ret = isWindow ? target[prop] : target[method]
  // ie6,7,8 standard mode
  if (isWindow && typeof ret !== 'number') {
    // quirks mode
    ret = window.document.documentElement[method]
  }

  return ret
}
/**
 *
 * @param elem (element)
 */
export function getOffset (elem) {
  const elemRect = elem.getBoundingClientRect()
  const body = document.body
  // border
  const clientTop = elem.clientTop || body.clientTop || 0
  const clientLeft = elem.clientLeft || body.clientLeft || 0
  const scrollTop = getScroll(window, true)
  const scrollLeft = getScroll(window)

  return {
    top: elemRect.top + scrollTop - clientTop,
    left: elemRect.left + scrollLeft - clientLeft
  }
}

export function getBlob (buffer, format) {
  try {
    return new Blob(buffer, { type: format })
  } catch (e) {
    // 旧版本浏览器 Blob 的兼容
    const bb = new window.BlobBuilder() || new window.WebKitBlobBuilder() || new window.MSBlobBuilder()
    buffer.forEach(function (buf) {
      bb.append(buf)
    })

    return bb.getBlob(format)
  }
}

export function getImageUrl (value, size = 320, cache = true) {
  const origin = /localhost|m\.ipastbook/g.test(window.location.origin) ? 'https://ipastbook.com' : window.location.origin
  if (/^https?:\/\//.test(value)) {
    if (/amazonaws/.test(value) && cache) {
      value = value.replace('//ipastbook.s3.amazonaws.com', '//static.ipastbook.com')
      value = `${value}${encodeURIComponent('?size=' + size)}`
      return value
    }
    return value
  }
  if (/data:|blob:/.test(value)) {
    return value
  }
  return origin + value
}

export function loadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const w = img.naturalWidth
      const h = img.naturalHeight
      resolve({ w, h })
    }
    img.onerror = reject
    img.src = src
  })
}

export default {
  getScroll, getOffset, getBlob, getImageUrl
}
