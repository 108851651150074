<template>
  <div class="login-modal" :class="{mobile: isMobile}">
    <img src="../../../static/images/logo/iPastBook-logo.svg" height="24" alt="logo">
    <p class="login-desc">
      <span>社交賬號登入</span>
    </p>
    <div class="btn-groups">
      <a class="login-btn fb-login-btn" :href="FBAuthLink">
        <img src="../../../static/images/logo/facebook-logo.png" class="fb-login-icon"/>
      </a>
      <a class="login-btn ins-login-btn" :href="InsAuthLink" v-show="false">
        <img src="../../../static/images/logo/Instagram-logo.png" class="fb-login-icon"/>
      </a>
    </div>
    <p style="display: none;" class="login-subtitle">
      <a :href="InsAuthLink">
        <i class="fa fa-instagram"/> Instagram 登入
      </a>
    </p>
    <div class="close-btn" @click="$emit('close')"></div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {}
  },
  computed: {
    FBAuthLink () {
      const link = '/social/facebook_login'
      const { needRedirect } = this.$route.meta
      return needRedirect ? `${link}?state=redirect_${encodeURIComponent(window.location.href)}` : link
    },
    InsAuthLink () {
      const link = '/social/instagram_login'
      const { needRedirect } = this.$route.meta
      return needRedirect ? `${link}?state=redirect_${encodeURIComponent(window.location.href)}` : link
    }
  },
  created () {}
}
</script>

<style type="text/scss" lang="scss">
  @import '../../styles/layout/LoginModal';
</style>
