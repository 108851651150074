<template>
  <div class="book" :class="{guide: book.guide, finalized: !book.editable}">
    <!--封面内容-->
    <div class="img-btn" @click="previewLink">
      <cover :book="book"></cover>
    </div>
    <!--书籍内容-->
    <div class="book-info">
      <h4 class="book-title text-ellipsis">
        <a @click="previewLink">
          <i :class="['fa', iconClass]" aria-hidden="true"></i>
          {{book.title}}
        </a>
      </h4>
      <div class="info-detail">
        <p class="hidden-xs">{{book.author}}</p>
        <p v-if="book.totalPage">{{innerStyle}} {{$t('book.page', {page: book.totalPage})}}</p>
        <template v-if="book.type === 'diarybook'">
          <template v-if="book.totalPage">
            <p class="hidden-xs">{{$t('book.labelStartedWith')}}: {{book.startDate}}</p>
            <p class="hidden-xs">{{$t('book.labelEndWith')}}: {{book.endDate}}</p>
          </template>
          <p class="text-mini">
          <span v-if="book.createdAt === book.updatedAt || book.updatedAt">
            <i class="icon ion-ios-information text-done"></i>
            {{$t('book.labelCreatedAt')}} {{formatDate(book.createdAt)}}
          </span>
            <span v-else>
            <i class="icon ion-android-alert text-orange"></i>
            {{$t('book.labelUpdatedAt')}} {{formatDate(book.updatedAt)}}
          </span>
          </p>
        </template>
      </div>
      <div class="book-btns">
        <button class="btn default edit" @click="handleEdit" v-if="book.editable">
          {{$t('book.buttonEdit')}}
        </button>
        <router-link :to="handleLink('review', book.bid, book.totalPage, book.source)" v-else>
          <button class="btn default">
            {{$t('book.buttonPreview')}}
          </button>
        </router-link>
        <button class="btn red purchase" :class="{disabled: isChecking}" @click.prevent="checkBookStatus" v-if="book.totalPage">
          <i class="fa fa-spinner fa-spin" v-if="isChecking"></i>
          {{$t('book.buttonPurchase')}}
        </button>
      </div>
    </div>
    <div class="book-item__action bin" @click="deleteBook">
      <i class="fa fa-times" aria-hidden="true" />
    </div>
    <transition name="slide-down">
      <div class="action-wrapper" v-if="showAction" @click="showAction = false">
        <div class="sub-action" @click.stop="shareBook" v-show="false">
          <div><i class="fa fa-share"></i></div>
          <p>分享</p>
        </div>
        <div class="sub-action" @click.stop="deleteBook">
          <div>
            <i class="fa fa-trash"></i>
          </div>
          <p>{{$t('button.delete')}}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import config from 'src/app.config'
import moment from 'moment'
import FBShare from '../../../mixins/FBShare'
import { mapGetters } from 'vuex'
import Cover from '../../../components/Cover'
import api from 'src/api'

export default {
  name: 'book',
  components: { Cover },
  mixins: [FBShare],
  props: {
    book: {
      type: Object,
      default: function () {
        return {
          bid: '',
          title: '',
          author: '',
          cover: '',
          createdAt: new Date(),
          updatedAt: new Date(),
          endAt: '',
          status: '0',
          template: 'common.text.a5Waterfall',
          totalPage: 0,
          editable: true,
          public: false,
          guide: false
        }
      }
    }
  },
  data () {
    return {
      template: config.templates,
      finalizedConfig: [
        { langRef: 'jp', className: 'jp' },
        { langRef: 'en', className: 'en' },
        { langRef: 'zh', className: 'zh' },
        { langRef: 'ko', className: 'en' }
      ],
      langRef: 'en',
      showAction: false,
      isChecking: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    iconClass () {
      if (this.book.type === 'photobook') {
        return 'fa-photo text-primary'
      }
      if (this.book.type === 'calendar') {
        return 'fa-calendar text-primary '
      }
      return 'fa-book text-fb'
    },
    innerStyle () {
      const { template, templateStyle, design } = this.book
      let title
      let material
      let style
      switch (this.book.type) {
        case 'diarybook':
          return this.$t(template)
        case 'photobook':
          if (template === 'album') {
            return 'A4雜誌冊'
          }
          title = t => `${t}相片書`
          material = design.template.includes('leather') ? '皮面' : '布藝'
          style = '簡約版'
          if (templateStyle === 'magazine-1' && templateStyle.includes('travel')) {
            style = '旅行風'
          }
          if (templateStyle.includes('love')) {
            style = '爱情風'
          }
          if (templateStyle.includes('japan')) {
            style = '日系風'
          }
          if (templateStyle.includes('baby')) {
            style = '幼兒版'
          }
          if (templateStyle.includes('child')) {
            style = '童趣版'
          }
          return title(material) + ' ' + style
        case 'calendar':
          return '月曆'
        default:
          return ''
      }
    }
  },
  methods: {
    handleEdit () {
      if (this.book.type === 'photobook' || this.book.type === 'calendar') {
        window.location.href = `https://ipastbook.com/app/design/album/?aid=${this.book.bid}&type=${this.book.type}&from=${encodeURIComponent(window.location.href)}`
        return
      }
      const { bid, totalPage, source, isGettingPages, isGettingPosts, pagesInitialized, postsInitialized, patched, insInitialized, isGettingIns } = this.book
      if (!isGettingPages && !isGettingPosts && !pagesInitialized && !postsInitialized && !patched && !totalPage && !insInitialized && !isGettingIns) {
        this.$emit('empty')
        return
      }
      if (!totalPage) {
        if (pagesInitialized || isGettingPosts) {
          this.$router.push({ name: 'selection', query: { bid, sid: source, tab: 'facebook' } })
          return
        }
        // 当前个人页数据未导入，粉丝页数据已经导入时
        if (pagesInitialized && (!postsInitialized || isGettingPosts || !insInitialized || isGettingIns)) {
          this.$router.push({ name: 'selection', query: { bid, sid: source, tab: 'pages' } })
          return
        }
        // 当前未导入个人页、粉丝页且当前只存在补写内容时
        if (!postsInitialized && !pagesInitialized && !insInitialized && patched) {
          this.$router.push({ name: 'selection', query: { bid, sid: source, tab: 'patch' } })
          return
        }
        // 当前 Instagram 数据导入，其他数据未导入时
        if (!totalPage && (insInitialized || isGettingIns)) {
          this.$router.push({ name: 'selection', query: { bid, sid: source, tab: 'instagram' } })
          return
        }
        this.$router.push({ name: 'selection', query: { bid, sid: source } })
      }
      if ((postsInitialized || pagesInitialized || patched || insInitialized) && totalPage) {
        this.$router.push({ name: 'preview', query: { bid, sid: source } })
        return
      }
      this.$router.push({ name: 'selection', query: { bid, sid: source } })
    },
    previewLink () {
      if (this.book.type === 'photobook' || this.book.type === 'calendar') {
        window.location.href = `https://ipastbook.com/app/design/album/?aid=${this.book.bid}&type=${this.book.type}&from=${encodeURIComponent(window.location.href)}`
        return
      }
      if (!this.book.totalPage) {
        this.handleEdit()
      }
      if (this.book.totalPage) {
        if (!this.book.editable) {
          this.$router.push({ name: 'review', query: { bid: this.book.bid, sid: this.book.source } })
          return
        }
        this.$router.push({ name: 'preview', query: { bid: this.book.bid, sid: this.book.source } })
      }
    },
    formatDate (date) {
      return moment(date).format('YYYY/MM/DD')
    },
    shareBook () {
      this.showAction = false
      this.FBShareBook(this.book.bid)
    },
    checkBookStatus () {
      if (this.book.type !== 'diarybook') {
        if (this.isChecking) return
        this.isChecking = true
        const checkHandler = {
          photobook: api.checkPhotoBook,
          calendar: api.checkCalendar
        }
        const request = checkHandler[this.book.type]
        request(this.book.bid).then(data => {
          this.isChecking = false
          const { pageIndex, pagesValid } = data
          if (pageIndex + 1) {
            this.$confirm({
              title: `书籍第${pageIndex + 1}頁圖片缺失`,
              okText: '查看',
              cancelText: '知道了',
              onOk: (modal) => {
                modal.close()
                const origin = /test|localhost/.test(window.location.origin) ? window.location.origin : 'https://ipastbook.com'
                window.location.href = `${origin}/app/design/album/?aid=${this.book.bid}&type=${this.book.type}`
              }
            })
          } else if (pagesValid) {
            const tip = pagesValid < 0 ? '書籍頁數太少，再去寫寫吧' : '書籍頁數太多了，去刪點頁數吧'
            this.$confirm({
              title: tip,
              okText: '查看',
              cancelText: '知道了',
              onOk: (modal) => {
                modal.close()
                const origin = /test|localhost/.test(window.location.origin) ? window.location.origin : 'https://ipastbook.com'
                window.location.href = `${origin}/app/design/album/?aid=${this.book.bid}&type=${this.book.type}`
              }
            })
          } else {
            this.$router.push({
              name: 'order-check',
              query: { bid: this.book.bid, sid: this.book.source, bookType: this.book.type }
            })
          }
        }).catch(e => {
          this.isChecking = false
        })
      } else {
        this.$router.push({
          name: 'order-check',
          query: { bid: this.book.bid, sid: this.book.source, bookType: this.book.type }
        })
      }
    },
    deleteBook () {
      this.$emit('delete', this.book)
    },
    handleLink (name, bid, page, source) {
      if (!bid) {
        this.$toast({
          message: '書籍無顯示內容'
        })
        return
      }
      return { name, query: { bid, sid: source } }
    }
  }
}
</script>

<style type="text/scss" lang="scss" scoped>
  @import '../../../styles/layout/Book';
</style>
