var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'one': _vm.one,
        'two': _vm.two,
        'three': _vm.three,
        'failed': _vm.failed,
        'done': _vm.done
        },attrs:{"id":"loading-block"}})
}
var staticRenderFns = []

export { render, staticRenderFns }