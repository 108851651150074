<template>
  <div class="shelf">
    <template v-if="promotionLoaded">
      <img class="promotion-image" :src="promotionImages.large" @click="checkPromotion" alt="">
    </template>
    <div class="container">
      <div class="shelf-tabs">
        <!-- <div class="shelf-tabs__item-wrapper is-clearfix">
          <div :class="['shelf-tabs__item', {'active': item.value === activeTab}]"
               @click="setActiveTab(item)"
               v-for="(item, index) in tabs" :key="index">
            {{item.name}}
            <span v-if="item.new" class="label label-danger">new</span>
          </div>
        </div> -->
      </div>
      <div class="text-center create-book-btn" v-if="activeTab !== 'diarybook' || diaryBookEnable">
        <button class="btn primary" :class="[isCreating ? 'disabled' : '']" @click="createBook">
          <i class="fa fa-spinner fa-spin" v-show="isCreating"></i>
          <span v-if="isCreating">创建中...</span>
          <span v-else>
          新建
          <template v-if="activeTab === 'photobook'">相片書</template>
          <template v-else-if="activeTab === 'calendar'">月曆</template>
          <template v-else>時光書</template>
        </span>
        </button>
      </div>
      <!--书架加载-->
      <div class="text-center" v-if="!listDataLoaded[activeTab]">
        <p class="loading-icon"></p>
        <p class="text-gray">{{$t('shelf.tipLoading')}}</p>
      </div>

      <template v-else>
        <div class="book-list clearfix" v-if="currentList.length">
          <book v-for="(book) in currentList" :key="book.bid"
                @delete="deleteAlbumBook"
                @empty="showImport = true"
                :book="book"></book>
        </div>
        <div v-else class="text-center" style="margin-top: 2rem;">
          <img src="../../../static/images/placeholder/pic_empty.png" width="120" alt="">
          <p>您還沒有作品</p>
          <p>趕緊創建一本吧</p>
        </div>
      </template>
    </div>
    <div class="fb-customerchat"
         :ref="user.uid"
         logged_in_greeting="hi，有做書問題可以私訊我哦！"
         logged_out_greeting="hi，有做書問題可以私訊我哦！"
         page_id="104557935331848">
    </div>
    <!--  选择导入内容  -->
    <modal :show="showImport">
      <content-import slot="body"
                      @ins="startInsAuth"
                      @patch="startPatch"
                      @close="closeImport"
                      @select="selectSource"/>
    </modal>
    <!--  补写书籍内容  -->
    <modal :show="showPatch" class="patch-modal">
      <patch-item ref="patch" slot="body"></patch-item>
      <div class="clearfix" slot="footer">
        <button class="btn plant pull-left" @click="showPatch = false">{{$t('button.cancel')}}</button>
        <button class="btn primary pull-right" @click="savePatchItem">{{$t('button.ok')}}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { orderBy, last, find, filter, findIndex, cloneDeep } from 'lodash'
import api from 'src/api/'
import generateCover from 'src/_utils/generateCover'
import config from 'src/app.config'
import Book from './Book/Book.vue'
import ContentImport from './ContentImport'
import PatchItem from '../Design/Selection/PatchItem'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'shelf',
  components: { Book, ContentImport, PatchItem },
  data () {
    return {
      booksData: [],
      dataLoaded: false,
      showModal: false,
      showDialog: false,
      bookEmpty: false,
      fetching: false,
      showImport: false,
      showPatch: false,
      uploading: false,
      isNavigating: false,
      activeTab: 'diarybook',
      isCreating: false,
      shelfList: {
        diarybook: [], // 日记书数据
        photobook: [], // 相册书数据
        calendar: [] // 月历
      },
      listDataFetching: {
        diarybook: false,
        photobook: false,
        calendar: false
      },
      listDataLoaded: {
        diarybook: false,
        photobook: false,
        calendar: false
      },
      diaryBookEnable: false
    }
  },
  computed: {
    ...mapGetters([
      'user', 'promotionLoaded', 'promotionImages', 'promotionConf'
    ]),
    tabs () {
      return [
        { name: '時光書', value: 'diarybook' },
        { name: '相片書', value: 'photobook' }
      ]
    },
    currentList () {
      return this.shelfList[this.activeTab]
    }
  },
  watch: {
    activeTab: function (val) {
      if (!this.listDataFetching[val] && !this.listDataLoaded[val]) {
        this.fetchCurrentTab(val)
      }
    }
  },
  created () {
    const { tab } = this.$route.query
    if (['diarybook', 'photobook', 'calendar'].includes(tab)) {
      this.activeTab = tab
    }
    this.fetchCurrentTab(tab)
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    checkPromotion () {
      window.location.href = this.promotionConf.introLink
    },
    fetchCurrentTab (val) {
      if (val === 'photobook') {
        this.fetchPhotoBooks()
      } else if (val === 'calendar') {
        this.fetchCalendars()
      } else {
        this.fetchBooks()
      }
    },
    setActiveTab (item) {
      this.activeTab = item.value
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, tab: item.value } })
    },
    deleteAlbumBook (book) {
      if (this.activeTab === 'diarybook' && this.shelfList.diarybook.length <= 1) {
        this.$confirm({
          title: '書架至少保留一本書！',
          cancel: false
        })
        return
      }
      this.$confirm({
        title: '刪除書籍？',
        onOk: (modal) => {
          modal.close()
          const bookDeleteHandler = {
            diarybook: api.deleteDiaryBook,
            photobook: api.deletePhotoBook,
            calendar: api.deleteCalendar
          }
          const tab = this.activeTab || 'diarybook'
          const request = bookDeleteHandler[tab]
          request(book.bid).then(data => {
            if (data.errors) {
              this.$toast({
                message: '刪除失敗'
              })
              return
            }
            const books = cloneDeep(this.shelfList[tab])
            const currentIndex = findIndex(books, { bid: book.bid })
            books.splice(currentIndex, 1)
            this.shelfList[tab] = books
            this.$toast({
              message: '已刪除'
            })
          })
        }
      })
    },
    createBook () {
      if (this.activeTab !== 'diarybook') {
        this.$router.push(`/templates?source=${this.activeTab}`)
      } else {
        this.isCreating = true
        api.createDiaryBook().then(data => {
          this.isCreating = false
          if (data.errors) {
            this.$toast({
              message: '最多創建5本時光書'
            })
            return
          }
          const newBook = data
          const books = this.handleBooksData([newBook])
          this.shelfList.diarybook = this.shelfList.diarybook.concat(books)
        }).catch(e => {
          this.isCreating = false
        })
      }
    },
    fetchBooks () {
      this.fetching = true
      this.listDataFetching.diarybook = true
      api.fetchBooks(this.user.uid).then(data => {
        this.dataLoaded = true
        if (data.errors || !data) {
          const errorCode = data.errors ? data.errors.code : ''
          const message = errorCode ? this.$t('shelf.tipBookFailed') + errorCode : this.$t('shelf.tipBookFailed')
          this.$toast({
            message,
            position: 'bottom'
          })
          return
        }
        this.listDataFetching.diarybook = false
        this.dataLoaded = true
        this.listDataLoaded.diarybook = true
        const { ableToCreateNew, books } = data
        this.diaryBookEnable = ableToCreateNew
        this.shelfList.diarybook = this.handleBooksData(books)
        this.fetching = false
      })
    },
    fetchPhotoBooks () {
      this.listDataFetching.photobook = true
      api.fetchPhotoBooks().then(data => {
        this.listDataFetching.photobook = false
        this.dataLoaded = true
        this.listDataLoaded.photobook = true

        data = data.map(album => {
          album.type = 'photobook'
          album.title = album.design.title
          album.author = album.design.author
          album.coverUrl = generateCover({ design: album.design })
          return album
        })
        this.shelfList.photobook = data
        this.fetching = false
      }).catch(e => {
        this.listDataFetching.photobook = true
      })
    },
    fetchCalendars () {
      this.listDataFetching.calendar = true
      api.fetchCalendars().then(data => {
        this.listDataFetching.calendar = false
        this.dataLoaded = true
        this.listDataLoaded.calendar = true

        data = data.map(album => {
          album.type = 'calendar'
          album.design = album.cover
          album.title = album.cover.title
          // 台历写死页数
          album.totalPage = 13
          album.coverUrl = generateCover({
            design: {
              cover: album.cover,
              template: album.cover.coverTemplate,
              title: album.cover.title
            },
            isCalendar: true
          })
          return album
        })
        this.shelfList.calendar = data
        this.fetching = false
      }).catch(e => {
        this.listDataFetching.calendar = true
      })
    },
    // 处理书籍数据
    handleBooksData (booksData) {
      booksData.map((item, index) => {
        const months = orderBy(item.currentChapters, ['month'], ['asc'])
        if (months.length) {
          item.startDate = months[0].month.substr(0, 4) + '/' + months[0].month.substr(4, 2)
          item.endDate = last(months).month.substr(0, 4) + '/' + last(months).month.substr(4, 2)
        }
        item.type = 'diarybook'

        try {
          item.retypesetMonths = item.retypesetMonths[item.typeset] ? item.retypesetMonths[item.typeset] : []
          if (item.retypesetMonths.length) {
            item.isTypesetting = true
          }
          item.title = item.design.title
          item.author = item.design.author
          item.coverUrl = generateCover({ design: item.design, chapters: item.currentChapters })
          item.template = find(config.templates, { name: item.typeset }).desc
          delete item.deletedChapters
          delete item.currentChapters
        } catch (err) {
          console.error(err)
        }
        return item
      })
      const currentBooks = filter(booksData, { editable: true })
      const finalizeBooks = filter(booksData, { editable: false })
      return currentBooks.concat(finalizeBooks)
    },
    // 处理相片书数据
    formatAlbumData (albums) {
      albums = albums.map(album => {
        album.type = 'photobook'
        album.title = album.design.title
        album.author = album.design.author
        album.cover = generateCover(album.design)
        return album
      })
      return albums
    },
    // 删除拉新邀请
    closeImport () {
      this.showImport = false
    },
    selectSource (sources) {
      this.showImport = false
      this.setLoading(true)
      const { bid } = this.currentList[0]
      const { uid } = this.user
      let facebookImportLink = `/social/facebook_data?uid=${uid}&bid=${bid}`
      facebookImportLink = sources.reduce((total, item) => {
        total = `${total}&source=${item}`
        return total
      }, facebookImportLink)
      window.location.href = facebookImportLink
    },
    startInsAuth () {
      const { bid } = this.currentList[0]
      const { uid } = this.user
      window.location.href = `/social/instagram_data?uid=${uid}&bid=${bid}`
    },
    startPatch () {
      this.showImport = false
      this.showPatch = true
    },
    savePatchItem () {
      if (this.uploading) return
      const { content, title, date, time, images } = this.$refs.patch
      let picList = images.slice()
      picList = picList.map(item => {
        return item[0]
      })
      if (!content && !picList.length) {
        this.$toast({
          message: this.$t('selection.tipCompletedPatch')
        })
        return
      }
      const dateArr = date.split('-')
      if (dateArr[0].length !== 4) {
        this.$toast({
          message: this.$t('selection.tipTimeError')
        })
        return
      }
      const item = {
        title,
        content,
        postDate: `${date}T${time}`,
        picList
      }
      this.$toast({
        message: this.$t('selection.tipSaving'),
        duration: 1000,
        position: 'bottom'
      })
      this.showModal = false
      const { source, bid } = this.currentList[0]
      this.uploading = true
      api.createItemContent(source, item).then(data => {
        this.uploading = false
        if (data.errors) return
        this.$toast({
          message: this.$t('selection.tipSaved'),
          duration: 1500,
          icon: 'fa-check-circle'
        })
        this.$router.push({ name: 'selection', query: { bid, sid: source, tab: 'patch' } })
      }).catch(e => {
        this.uploading = false
        this.$toast({
          message: '保存失败'
        })
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  @import '../../styles/layout/Shelf';
</style>
