import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      isIOS: window.isIOS,
      isAndroid: window.isAndroid,
      isMobile: window.isMobile
    }
  },
  computed: {
    ...mapGetters([
      'messages'
    ])
  }
}
