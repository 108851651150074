import Vue from 'vue'
import ViewerComponent from './Viewer.vue'

const Viewer = Vue.extend(ViewerComponent)

const getInstance = (images) => {
  // 注意此处在 Swiper dom 生成前把图片一并传入示例，防止图片异步加载
  return new Viewer({
    el: document.createElement('div'),
    images
  })
}

const removeDOM = (evt) => {
  if (evt.target.parentNode) {
    evt.target.parentNode.removeChild(evt.target)
  }
}
/**
 * @component this.$viewer({
 *    images: [],
 *    activeIndex: 1
 * })
 * @param images - [] 图片数组
 * @param activeIndex - [] 当前图片索引
 */
const $viewer = (options = {}) => {
  const images = options.images || []
  const instance = getInstance(images)
  instance.activeIndex = options.activeIndex || 0
  instance.onClose = function () {
    removeDOM({ target: instance.$el })
  }
  document.body.appendChild(instance.$el)
  return instance
}

const plugin = {
  install: (Vue) => {
    Vue.prototype.$viewer = $viewer
  }
}

Vue.use(plugin)
