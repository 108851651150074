import axios from './axios'
import { isObject, isString } from 'lodash'

const api = {
  /**
   * 照片书产品模板
   * @see https://ipastbook.com/admin/docs/#!/photobook_templates/Photo_Book_Template_list
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchMagazineTemplates () {
    return axios.get('/api/0.1/photobook_templates/')
  },
  /**
   * canvas 模板服务
   * @param template {string}
   * @see https://weixinshu.com/dashboard/canvas/templates?type=cover-magazine
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchMagazinePage (template) {
    return axios.get(`https://canvas.xinshu.me/html/${template}?size=320`)
  },
  // 加载优惠信息
  fetchPromotion () {
    return axios.get(`https://s3-ap-northeast-1.amazonaws.com/ipastbook/media/config/promotion.json?ver=${Date.now()}`)
  },
  /**
   * 用户信息
   * @param uid {string}
   * @see https://ipastbook.com/admin/docs/#!/users/User_retrieve
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchUser (uid) {
    return axios.get(`/api/0.1/users/${uid}/`)
  },
  /**
   * 是否聊过天
   * @param uid {string}
   * @see https://ipastbook.com/admin/docs/#!/users/User_talked
   * @return {Promise<AxiosResponse<any>>}
   */
  checkUserTalked (uid) {
    return axios.get(`/api/0.1/users/${uid}/talked/`)
  },

  /**
   * 请求用户数据
   * @param uid {string}
   * @see https://ipastbook.com/admin/docs/#!/books/Book_list
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchBooks (uid) {
    return axios.get(`/api/0.1/books/?uid=${uid}`)
  },
  /**
   * 请求书籍
   * @param bid {string}
   * @see https://ipastbook.com/admin/docs/#!/books/Book_retrieve
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchBook (bid) {
    return axios.get(`/api/0.1/books/${bid}/`)
  },
  /**
   * 请求用户照片书
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchPhotoBooks () {
    return axios.get('/api/0.1/photobooks/')
  },
  // 请求相册书数据
  fetchPhotoBook (bid) {
    return axios.get(`/api/0.1/photobooks/${bid}/`)
  },
  // 请求用户月历
  fetchCalendars () {
    return axios.get('/api/0.1/calendars/')
  },
  // 请求月历
  fetchCalendar (bid) {
    return axios.get(`/api/0.1/calendars/${bid}/`)
  },
  // 请求相片书模板
  fetchPhotoTemplates () {
    return axios.get('/api/0.1/photobook_templates/')
  },

  // 请求月历模板
  fetchCalendarTemplates () {
    return axios.get('/api/0.1/calendar_templates/')
  },
  /**
   * 创建日记书
   * @return {Promise<AxiosResponse<any>>}
   */
  createDiaryBook () {
    return axios.post('/api/0.1/books/')
  },

  // 创建相册书
  createPhotoBook (template) {
    let params = null
    if (isObject(template)) {
      params = template
    } else if (isString(template)) {
      params = { template }
    } else {
      params = { template: 'magazine', style: 'magazine-new' }
    }
    return axios.post('/api/0.1/photobooks/', params)
  },
  // 创建月历
  createCalendar (template) {
    let params = null
    if (isObject(template)) {
      params = template
    } else if (isString(template)) {
      params = { template }
    } else {
      params = { template: 'traditional', style: '2020-h-traditional' }
    }
    return axios.post('/api/0.1/calendars/', params)
  },
  // 删除时光书
  deleteDiaryBook (bid) {
    return axios.delete(`/api/0.1/books/${bid}/`)
  },

  // 刪除相冊書
  deletePhotoBook (bid) {
    return axios.delete(`/api/0.1/photobooks/${bid}/`)
  },

  // 删除月历
  deleteCalendar (bid) {
    return axios.delete(`/api/0.1/calendars/${bid}/`)
  },

  // 检查照片书信息
  checkPhotoBook (bid) {
    return axios.get(`/api/0.1/photobooks/${bid}/check/`)
  },
  // 检查月历信息
  checkCalendar (bid) {
    return axios.get(`/api/0.1/calendars/${bid}/check/`)
  },
  // 删除书籍
  deleteBook (bid) {
    return axios.delete(`/api/0.1/books/${bid}/`)
  },
  // 获取素材库
  fetchSocialInfo (sourceId, type) {
    return axios.get(`/api/0.1/socialbooks/${sourceId}/?type=${type}`)
  },
  /**
   * 补写内容
   * @param sid {string}
   * @param item {SourceItem}
   * @return {Promise<AxiosResponse<any>>}
   */
  createItemContent (sid, item) {
    return axios.post(`/api/0.1/socialbooks/${sid}/items/`, item)
  },
  /**
   * 更新书籍内容
   * @param sbid {string}
   * @param updateContent {BookContentMonths}
   * @return {Promise<AxiosResponse<any>>}
   */
  updateSocialBook (sbid, updateContent) {
    return axios.put(`/api/0.1/socialbooks/${sbid}/`, updateContent)
  },
  /**
   * 获取社交账户章节
   * @param sourceId {string}
   * @param scid {string}
   * @param page {number}
   * @param pageSize {number}
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchSocialChapter (sourceId, scid, page, pageSize) {
    return axios.get(`/api/0.1/socialbooks/${sourceId}/socialchapters/${scid}/items/?page=${page}&pagesize=${pageSize}`)
  },
  /**
   * 批量处理章节内容
   * @param sid {string}
   * @param scid {string}
   * @param config {SocialChapterAction}
   * @return {Promise<AxiosResponse<any>>}
   */
  handleChapterItem (sid, scid, config) {
    return axios.put(`/api/0.1/socialbooks/${sid}/socialchapters/${scid}/`, config)
  },
  /**
   * 操作单个条目内容
   * @param sid {string}
   * @param scid {string}
   * @param itemId {string}
   * @param data {ChapterUpdateContent}
   * @return {Promise<AxiosResponse<any>>}
   */
  handleItemContent (sid, scid, itemId, data) {
    return axios.put(`/api/0.1/socialbooks/${sid}/socialchapters/${scid}/items/${itemId}/`, data)
  },
  /**
   * 获取粉丝页、相册、Facebook信息
   * @param bid {string}
   * @param since {string}
   * @param until {string}
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchPages ({ bid, since, until }) {
    if (!bid) {
      return axios.get(`/api/0.1/socials/import/?since=${since}&until=${until}`)
    } else {
      if (!since) {
        return axios.get(`/api/0.1/socials/import/?bid=${bid}`)
      }
      return axios.get(`/api/0.1/socials/import/?bid=${bid}&since=${since}&until=${until}`)
    }
  },
  /**
   * 请求封面信息
   * @param bid {string}
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchCoverDesign (bid) {
    return axios.get(`/api/0.1/books/${bid}/design/`)
  },
  /**
   * 更新封面
   * @param bid {string}
   * @param designData {CoverDesign}
   * @return {Promise<AxiosResponse<any>>}
   */
  updateCoverDesign (bid, designData) {
    return axios.put(`/api/0.1/books/${bid}/design/`, designData)
  },

  // update or deleted chapterItem content by itemId
  /**
   *
   * @param bid
   * @param cid
   * @param itemId
   * @param data :Object
   *  {content: 'xxx', selectPics: [], title: ''} -> update
   *  optional: {deleted: true/false, deleteContent: true/false} -> deleted chapterItem or all chapterItem's content
   * @returns {*}
   */
  chapterContentActions (bid, cid, itemId, data) {
    return axios.put(`/api/0.1/books/${bid}/chapters/${cid}/items/${itemId}/`, data)
  },
  /**
   * 更新数据来源
   * @param sid {string}
   * @param scid {string}
   * @param type {string}
   * @return {Promise<AxiosResponse<any>>}
   */
  updateItems (sid, scid, type) {
    return axios.put(`/api/0.1/socialbooks/${sid}/socialchapters/${scid}/flush/`, { type })
  },
  // 创建书籍
  createChapterItem (bid, item) {
    return axios.post(`/api/0.1/books/${bid}/createitem/`, item)
  },
  uploadImage (formData) {
    return axios.post('/api/0.1/images/', formData)
  },
  // 预览前出发排版
  triggerTypeset (bid) {
    return axios.put(`/api/0.1/books/${bid}/typeset/`)
  },
  /**
   * fetch user's all coupon
   * @returns {*} - []
   */
  fetchAllCoupon () {
    return axios.get('/api/0.1/coupons/')
  },
  // checkout user's coupon
  checkCoupon (coupon) {
    if (!(/^\w/.test(coupon))) { // 校验优惠券
      return new Promise(resolve => {
        resolve({})
      })
    }
    return axios.get(`/api/0.1/coupons/${coupon}/`)
  },
  fetchOfficialCoupon ({ uid, page }) {
    let apiUrl = `/api/0.1/coupons/official/?page=${page}&pageSize=10`
    if (uid) {
      apiUrl = apiUrl + '&uid=' + uid
    }
    return axios.get(apiUrl)
  },
  fetchPrice (countryCode) {
    return axios.get(`/api/0.1/prices/search/?country_code=${countryCode}`)
  },
  createAddress (address) {
    /**
     * address: {
     *  "first_name":"Donald",
        "last_name":"Trump",
        "line1":"4thFloor",
        "line2": "unit#34",
        "city": "SAn Jose",
        "country_code": "US",
        "postal_code": "95131",
        "state": "CA",
        "phone": "011862212345678",
        "email": "dtrump@us.com",
        "type": "delivery/bill"
       }
     *
     */
    return axios.post('/api/0.1/addresses/', address)
  },
  fetchAddress (addressId) {
    return axios.get(`/api/0.1/addresses/${addressId}/`)
  },
  // fetch all countries
  fetchCountries () {
    return axios.get('/api/0.1/countrys/')
  },
  updateAddress (addressId, address) {
    return axios.put(`/api/0.1/addresses/${addressId}/`, address)
  },
  fetchOrderAddresses (type) {
    return axios.get(`/api/0.1/addresses/?type=${type}`)
  },
  updateIdInfo (idInfo) {
    return axios.put('/api/0.1/addresses/customs_info/', idInfo)
  },
  fetchKey (method) {
    return axios.get(`/api/0.1/orders/key/?method=${method}`)
  },
  postOrder (order) {
    return axios.post('/api/0.1/orders/', order)
  },
  /**
   *
   */
  triggerCoupon (coupon) {
    if (!(/^\w/.test(coupon))) {
      return Promise.resolve(true)
    }
    return axios.get(`/api/0.1/coupons/${coupon}/claim/`)
  },
  fetchOrders (uid) {
    return axios.get(`/api/0.1/orders/?uid=${uid}`)
  },
  fetchOrder (orderId) {
    return axios.get(`/api/0.1/orders/${orderId}/`)
  },
  fetchOrderPrintbook (orderId) {
    return axios.get(`/api/0.1/orders/${orderId}/printbook/`)
  },
  deleteOrder (orderId) {
    return axios.delete(`/api/0.1/orders/${orderId}/`)
  },

  // 获取当前用户的所有下线
  fetchReferral () {
    return axios.get('/api/0.1/referrals/')
  },
  // 触发用户邀请绑定
  triggerUserBind (uid) {
    return axios.put(`/api/0.1/referrals/bind/?parent=${uid}`)
  },
  // 代理规则
  fetchAgentRules (locale) {
    return axios.get(`/api/0.1/rewardrules/${locale}/`)
  },
  // 代理账户详情
  fetchAgentInfo () {
    return axios.get('/api/0.1/rewards/overview/')
  },
  // 代理邀请用户
  fetchAgentReferList (page) {
    return axios.get(`/api/0.1/rewards/?page=${page}`)
  },
  // 请求代理粉丝
  fetchAgentFansList (page) {
    return axios.get(`/api/0.1/rewards/fans/?page=${page}`)
  },

  // 代理提现
  withdrawAll (account) {
    return axios.put('/api/0.1/rewards/withdraw/all/', { accountId: account })
  },
  // 代理按单提现
  withdrawByOrder (orderId) {
    return axios.put(`/api/0.1/rewards/${orderId}/withdraw/`)
  },
  // 提现账号
  fetchPayAccount () {
    return axios.get('/api/0.1/payaccounts/')
  },
  // 创建提现账户
  createPayAccount (info) {
    return axios.post('/api/0.1/payaccounts/', info)
  },
  // 更新提现账户
  updatePayAccount ({ id, info }) {
    return axios.put(`/api/0.1/payaccounts/${id}/`, info)
  },
  // 请求代理提现记录
  fetchWithdrawRecord (page) {
    return axios.get(`/api/0.1/withdraw_records/?page=${page}`)
  },

  // 提交代理申请
  applyAgentRequest () {
    return axios.post('/api/0.1/agent_records/')
  },
  // 查看代理申请
  fetchAgentApply () {
    return axios.get('/api/0.1/agent_records/applied/')
  },
  // 请求当前团购信息
  fetchLatestGroup () {
    return axios.get('/api/0.1/group_buyings/latest/')
  },

  // 请求当前gid 团购信息
  fetchGroupInfo (gid, uid) {
    const apiUrl = uid ? `/api/0.1/group_buyings/${gid}/?uid=${uid}` : `/api/0.1/group_buyings/${gid}/`
    return axios.get(apiUrl)
  },

  // 开团支付接口
  startGroupCheckout (order) {
    return axios.post('/api/0.1/group_buyings/', order)
  },

  // 参加已开团
  joinGroupCheckout (gid, order) {
    return axios.put(`/api/0.1/group_buyings/${gid}/join/`, order)
  },

  // 确认成团
  enSureGroupEnd (gid) {
    return axios.put(`/api/0.1/group_buyings/${gid}/finish/`)
  },
  // 团购历史列表
  fetchRecordList (page) {
    return axios.get(`/api/0.1/group_buyings/history/?page=${page}`)
  },

  // 团购支付结果
  fetchGroupCheckout (oid) {
    return axios.get(`/api/0.1/group_buying_orders/${oid}/`)
  },
  // 随机参团
  randomJoinGroup (order) {
    return axios.put('/api/0.1/group_buyings/random_join/', order)
  },
  fetchEventConfig () {
    return axios.get('/api/0.1/referral_activities/current/')
  },
  fetchEventDetail (id) {
    return axios.get(`/api/0.1/referral_activities/${id}/children/`)
  },
  // fetch users' testimonials
  fetchTestimonials (page = 1, language, tags) {
    let suffix = ''
    if (tags) {
      suffix = `&tags=${tags}`
    }
    return axios.get(`/api/0.1/testimonials/?page=${page}&language=${language}${suffix}`)
  },
  fetchTagPosts (page = 1, pageSize = 10) {
    return axios.get(`/api/0.1/tagged_posts/?page=${page}&pageSize=${pageSize}`)
  }
}

export default api
