<template>
  <div id="app" :data-page="$route.meta.root || $route.name"
       :class="{preview: ['preview', 'review'].indexOf($route.name) > -1}">
    <i-header :fixed="$route.meta.topFixed" v-if="$route.meta.root !== 'design'" :user="user"/>
    <template v-if="shouldEventShow">
      <modal :show="modalShow" @close="hideEventModal" class="promotion-banner">
        <div slot="body">
          <button class="close-btn" @click="hideEventModal">
            <i class="ion-close-round"></i>
          </button>
          <img :src="promotionImages.background" @click="saveCoupon" alt="">
        </div>
      </modal>
    </template>
    <keep-alive :include="['home', 'profile']">
      <router-view/>
    </keep-alive>
    <toast :message="loading.text" :value="loading.visible" :lock="true" closeable>
      <div class="loading-icon"></div>
    </toast>
    <!--登录框-->
    <modal :show="loginShow" class="login-content" @close="setLoginShow(false)">
      <login-modal @close="setLoginShow(false)" slot="body"/>
    </modal>
    <foot v-if="isFooterVisible"></foot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoginModal from 'src/components/layout/LoginModal.vue'
import api from 'src/api'
import Cookies from 'js-cookie'

export default {
  name: 'app',
  data: () => ({
    modalShow: false
  }),
  components: {
    LoginModal,
    Foot: () => import('./views/Home/Footer.vue')
  },
  computed: {
    ...mapGetters([
      'loading', 'user', 'loginShow', 'language', 'locale', 'promotionConf', 'promotionImages', 'promotionLoaded'
    ]),
    isFooterVisible () {
      return ['home', 'pricing', 'faq'].indexOf(this.$route.name) > -1
    },
    shouldEventShow () {
      return ['home', 'shelf'].indexOf(this.$route.name) > -1
    }
  },
  created () {
    if (this.locale === 'CN') {
      this.setLocale('TW')
    }
  },
  mounted () {
    this.loadPromotion()
  },
  methods: {
    ...mapActions([
      'setLoading', 'setLoginShow', 'setPromotionConf', 'setPromotionImages', 'setPromotionLoaded', 'setLocale'
    ]),
    // 初始优惠活动
    loadPromotion () {
      return api.fetchPromotion().then(data => {
        if (!data) {
          return Promise.reject(new Error('优惠配置加载失败'))
        }
        const langRef = this.language.substr(0, 2)
        const {
          coupon,
          start,
          end,
          timeout,
          action,
          introLink
        } = data
        const timestamp = Date.now()
        const promotion = data[langRef]

        if (data.enable && promotion.visible && timestamp <= end && timestamp >= start &&
          !Cookies.getJSON('hideEvent')) {
          this.setPromotionLoaded(true)
          this.setPromotionImages(promotion)
          this.setPromotionConf({
            coupon,
            start,
            end,
            timeout,
            action,
            introLink
          })
          if (!Cookies.getJSON('hideEventModal')) {
            const image = new Image()
            image.src = promotion.background
            image.onload = () => {
              this.modalShow = true
            }
          }
        }
        return this.promotionLoaded
      })
    },
    // 点击活动优惠券动作
    saveCoupon () {
      Cookies.set('hideEventModal', true)
      window.location.href = this.promotionConf.introLink
    },
    // 隐藏优惠活动，设置隐藏时间
    hideEventModal () {
      this.modalShow = false
      Cookies.set('hideEventModal', true, { expires: 2 })
    },
    hideEvent () {
      this.promotionLoaded = false
      Cookies.set('hideEvent', true, { expires: 1 })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  color: #333;

  &.preview {
    height: 100%;
    background-color: #f9f9f9;
  }

  .promotion-banner {
    .modal-body {
      padding: 0;
    }

    .modal-footer {
      display: none;
    }

    .close-btn {
      position: absolute;
      padding: 0;
      top: 4px;
      right: 4px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: #ccc;
      color: white;
      border: 1px solid #ccc;
    }
  }

  @media (min-width: 768px) {
    &[data-page='design'] {
      height: 100%;
    }
  }
  @media (max-width: 767px) {
    &[data-page='design'], &[data-page='order-trade'] {
      height: 100%;
      background-color: #f9f9f9;
    }
  }

  &[data-page='home'], &[data-page='landing-page'] {
    @media (min-width: 768px) {
      header.td {
        background: transparent;
        box-shadow: none;
        position: absolute;
      }
    }
  }

  &[data-page='profile'] {
    height: 100%;
    background-color: #f9f9f9;
  }

  &[data-page='profile'] {
    background: {
      color: #fdfdfd;
      image: url(../static/images/shelf/create.png);
      repeat: no-repeat;
      position: 50% bottom;
      size: contain;
    };
  }

  .login-content {
    .modal-footer {
      display: none;
    }
  }
}
</style>
