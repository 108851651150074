import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { state } from 'src/store/'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: state.language,
  fallbackLocale: 'en',
  messages: state.messages
})

export default i18n
