export const SET_LOADING = 'SET_LOADING'
export const SET_USER = 'SET_USER'
export const SET_STEP_SHOW = 'SET_STEP_SHOW'
export const SET_LOGIN_SHOW = 'SET_LOGIN_SHOW'
export const SET_PROMOTION_CONF = 'SET_PROMOTION_CONF'
export const SET_PROMOTION_IMAGES = 'SET_PROMOTION_IMAGES'
export const SET_PROMOTION_LOADED = 'SET_PROMOTION_LOADED'

export const SET_LOCALE = 'SET_LOCALE'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_MESSAGES = 'SET_MESSAGES'

export const SET_TIMELINE = 'SET_TIMELINE'
export const SET_CHAPTERS = 'SET_CHAPTERS'

export const SET_AVAILABLE_AMOUNT = 'SET_AVAILABLE_AMOUNT'
