<template>
  <div class="mobile-nav-list">
    <ul class="nav-root">
      <template v-if="user.uid">
        <li>
          <router-link to="/app/shelf">
            <i class="icon ion-ios-book-outline" data-icon="ion"></i>
            {{$t('header.nav.shelf')}}
          </router-link>
        </li>
        <li>
          <router-link to="/app/orders">
            <i class="ion-android-playstore icon" data-icon="ion"></i>
            {{$t('header.nav.orders')}}
          </router-link>
        </li>
      </template>
      <!-- <li>
        <a href="/testimonials">
          <i class="icon ion-ios-people" data-icon="ion"></i>
          {{$t('header.nav.share')}}
        </a>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: '',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  created () {}
}
</script>

<style type="text/scss" lang="scss">
@import '../../styles/layout/NavList.scss';
</style>
